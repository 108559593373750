@font-face {
  font-family: 'Dela Gothic One';
  // src: local('Dela'), url(./assets/fonts/dela.woff2) format('woff2');
  src: local('Dela'), url(./assets/fonts/RobotoCondensed-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('Roboto Condensed'), url(./assets/fonts/RobotoCondensed-Bold.ttf) format('truetype');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('Roboto Condensed'), url(./assets/fonts/RobotoCondensed-Regular.ttf) format('truetype');
  font-weight: normal;
  font-display: swap;
}

.site__preloader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transition: background-color .3s;
  background-color: #3E6FEC;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@keyframes lds-default {

  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url("./assets/images/header__bg-overlay.svg"), url("./assets/images/header__bg.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 1440px;
}

h1 {
  font-family: 'Dela Gothic One';
  font-size: 64px;
  line-height: 91.8%;
  font-weight: normal;
}

h2 {
  font-family: 'Dela Gothic One';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
}

a {
  text-decoration: none;
}

button {
  font-family: "Roboto Condensed", sans-serif;
}

.content {
  max-width: 1440px;
  margin: auto;
  position: relative;
}

.bg__image {
  position: absolute;
  right: -115px;
  bottom: -302px;
  width: 1200px;
  height: 507px;
  background: url(./assets/images/3dcombine.webp);
  user-select: none;
}

.home {
  overflow: hidden;
  min-height: 100vh;
}

.header__content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 100px;
}

.header__logo {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.header__logo a {
  font-size: 24px;
  font-family: "Dela Gothic One", sans-serif;
  color: #fff;
}

.header__left-side {
  display: flex;
  gap: 14px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.header__logo-info-box {
  padding: 4px 12px;
  border-radius: 58px;
  background: #3E6FEC;
  height: fit-content;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
}

.header__nav {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.header__nav .active {
  opacity: 1;
}

.header__nav-item {
  color: white;
  opacity: .66;
  font-weight: bold;
  cursor: pointer;
  transition: all .3s;
}

.header__nav-item:hover {
  opacity: 1;
}

.hero__socials {
  .hero__socials-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style: none;
    gap: 10px;
    margin: 0px;
    padding: 0px;
    position: relative;
    z-index: 1;
  }

  .hero__socials-list a {
    transition: opacity .3s;
  }

  .hero__socials-list a:hover {
    opacity: .7;
  }

  .hero__socials-item {
    font-weight: bold;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.42);
    border-radius: 15px;
    color: white;
    gap: 5px;
    display: flex;
    flex-direction: row;
    padding: 6px 10px;
    margin: 0px;
  }

  .hero__socials-item a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #fff;
  }

  .hero__socials-item img {
    width: 15px;
    height: 15px;
  }
}

.hero__wrapper {
  padding: 0px 100px;
  min-height: 483px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
}

.hero__title {
  max-width: 557px;
  line-height: 91.8%;
  color: #fff;
  margin: 0px;
  padding: 0px;
  z-index: 1;
  position: relative;
}

.page__wrapper {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start;
}

.page__wrapper_extended {
  padding-bottom: 300px !important;
}


.page__title {
  max-width: 557px;
  line-height: 91.8%;
  color: #fff0;
  margin: 0px;
  padding: 0px;
  -webkit-text-stroke: 1px #fff;
  text-transform: uppercase;
}

.page__subtitle {
  margin-top: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ABC0F7;
  max-width: 554px;

  strong {
    font-weight: bold;
    color: #fff;
  }
}



.hero__subtitle {
  max-width: 554px;
  line-height: 19px;
  color: #ABC0F7;
  margin: 0px;
  padding: 0px;
  position: relative;
  z-index: 1;

  strong {
    font-weight: bold;
    color: #fff;
  }
}

.hero__button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  position: relative;
  z-index: 1;
}

.hero__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 220px;
  width: 100%;
  min-height: 49px;
  background: linear-gradient(180deg, #FFE600 0%, #EBFF00 100%);
  box-shadow: 4px 4px 30px rgba(204, 208, 0, 0.67);
  border-radius: 5px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
  color: #3D3D3D;
  margin-top: 15px;
  transition: all 0.3s;
  border: 0px;
  cursor: pointer;
}

.hero__call-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 220px;
  width: 100%;
  min-height: 49px;
  background: none;
  box-shadow: none;
  border-radius: 5px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  border: 1px solid #f2f601;
  align-items: center;
  letter-spacing: -0.04em;
  color: #f2f601;
  margin-top: 15px;
  transition: all 0.3s;
  cursor: pointer;
  display: none;
}


@media screen and (max-width: 525px) {
  .hero__call-button {
    display: flex;
  }
}


.hero__button:hover {
  opacity: .9;
  transform: translateY(-1px);
}

.hero__button:active {
  opacity: .9;
  transform: translateY(2px);
  box-shadow: 0 0 0px 3px rgb(204 208 0 / 23%);
}


.delivery {
  margin-top: 357px;
  min-height: 777px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
}

.delivery__title {
  max-width: 392px;
  color: #252525;
  margin: 0px;
  padding-bottom: 25px;
}

.delivery__subtitle {
  max-width: 492px;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: rgba(37, 37, 37, 0.66);
  margin: 0px;

  strong {
    color: #252525;
  }
}


.delivery__bg {
  background: url(./assets/images/delivery-bg.svg);
  background-repeat: no-repeat;
  position: absolute;
  background-position: top right;
  background-size: contain;
  right: 0px;
  top: 0px;
  width: 517px;
  height: 777px;
  z-index: -1;
}

.delivery__bg-box {
  background: url(./assets/images/delivery.webp);
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: left center;
  z-index: -1;
}

.slider__arrow {
  width: 73px;
  height: 46px;
  background: #EBF0FD;
  border: 6px solid #FFFFFF;
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .5s;
}

.slider__arrow-disabled {
  opacity: 0;
  transform: translateX(10px);
}

.slider__arrow-left {
  transform: rotateY(180deg);
  left: -37px;
  position: absolute;
  z-index: 1;
}

.slider__arrow-right {
  position: absolute;
  right: -45px;
  z-index: 1;
}

.delivery__slider {
  margin: 0px !important;
  padding: 0px !important;
  max-width: 731px;
  min-height: 220px;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.delivery__slide-avatar {
  box-shadow: 0px 4px 9px #2461FF;
  border-radius: 5px;
  overflow: hidden;
  width: 54px;
  height: 54px;
}

.delivery__slider-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #0040E6;
  box-shadow: 0px 4px 27px rgba(39, 99, 255, 0.29);
  border-radius: 10px;
  max-width: 280px;
}

.delivery__slide-header {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  max-width: 100%;
  padding: 20px 20px 0px 20px;
}

.delivery__slide-title {
  margin: 0px;
  padding: 0px;
  font-weight: 700;
  font-size: 24px;
  color: #fff;
  max-width: 150px;
  line-height: 100%;
}

.delivery__slide-body {
  margin: 0px;
  padding: 10px 20px 20px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #FFFFFF;
}

.slider__wrapper {
  max-width: fit-content;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.gallery__title {
  color: #252525;
  margin: 0px;
  padding-bottom: 30px;
  max-width: 330px;
  line-height: 80%;
  ;
}

.gallery__slider {
  width: 100%;
}

.gallery__slide-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  object-fit: cover;
  height: 100%;
  cursor: pointer;
  transition: opacity .3s;
}

.gallery__slide-bg:hover {
  opacity: .7;
}

.gallery__modal {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #222, $alpha: .5);
  z-index: 200;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gallery__popup {
  border: 3px solid white;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  box-shadow: 0px 20px 47px -23px black;
}

.gallery__slide-big {
  width: 100%;
  height: 100%;
  max-height: 80vh;
}

.gallery__slide {
  border-radius: 14px;
  max-width: 367px !important;
  height: 200px !important;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.gallery__slide-title {
  padding: 0px 20px 20px 20px;
  margin: 0px;
  color: #fff;
  z-index: 1;
  position: relative;
}

.whyus {
  margin-top: 193px;
}

.whyus__wrapper-of-wrapper {
  background-color: #0040E6;
  width: 100%;
}

.whyus__avito {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: center;
  min-height: 180px;
  background-color: #F9F9F9;
  position: relative;
}

.whyus__avito-blur {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: url(./assets/images/avito-bg-blur.webp);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: 0;
}

.whyus__avito-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  position: relative;
  z-index: 1;
}

.whyus__avito-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 272px;
  min-height: 70px;
  background: #00AAFF;
  border-radius: 73px;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  color: #fff;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.whyus__avito-button:hover {
  background-color: #29b8ff;
}

.whyus__features {
  display: flex;
  flex-direction: row;
}

.whyus__features-image {
  position: relative;
  overflow: visible;
  display: flex;
  width: 40%;
}

.whyus__features-image-bg {
  background: url(./assets/images/boy-features-light.webp);
  position: absolute;
  width: 289px;
  height: 331px;
  background-size: contain;
  background-position: left bottom;
  background-repeat: no-repeat;
  left: 0px;
  bottom: 0px;
  z-index: 0;
}

.whyus__features-image-human {
  background: url(./assets/images/boy-features.webp);
  position: absolute;
  width: 557px;
  height: 557px;
  left: 0px;
  bottom: 0px;
  background-size: 557px 557px;
  background-repeat: no-repeat;
  background-position: bottom -29px left;
  overflow: visible;
}


.whyus__features-list {
  padding: 50px;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.whyus__features-list-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  margin: 0px;
}

.whyus__features-list-ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 834px;
}

.whyus__features-list-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: calc((100%/2) - 40px);
  padding: 20px;
}

.whyus__features-list-item-body {
  display: flex;
  flex-direction: column;
  gap: 5px;

  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    color: #FFFFFF;
    margin: 0px;
    padding: 0px;
  }

  p {
    padding: 0px;
    margin: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: rgba(255, 255, 255, 0.66);
  }
}

.wemake {
  margin-top: 70px;
}

.wemake__title {
  color: #252525;
  margin: 0px;
  padding-bottom: 30px;
  max-width: 330px;
  line-height: 80%;
}

.wemake__showcase {
  margin: 0px;
  list-style: none;
  padding: 0px;
  border-radius: 30px;
  overflow: hidden;
  filter: drop-shadow(9px 12px 0px rgba(0, 64, 230, 0.17));
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.wemake__showcase li {
  width: calc((100%/5) - 60px);
  padding: 30px;
  background: linear-gradient(126deg, #0040E6 0%, #2763FF 97.93%);
  display: flex;
  flex-direction: column;
  gap: 10px;

  .wemake__showcase-image {
    align-items: center;
    background: hsla(0, 0%, 100%, .22);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    height: 55px;
    justify-content: center;
    overflow: hidden;
    transition: height .3s;
    width: 45px;
    min-height: 55px;
  }

  img {
    width: 80%;
    height: auto;
    color: rgba(255, 255, 255, 0.66);
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #fff;
    margin: 0px;
    padding: 0px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: rgba(255, 255, 255, 0.66);
    max-width: 169px;
    margin: 0px;
    padding: 0px;
  }

  .wemake__button-wrapper {
    display: flex;
    overflow: hidden;
    transition: height .3s;
    height: 100%;
    align-items: flex-end;

    .wemake__button {
      max-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      outline: none;
      border: 2px solid rgba(255, 255, 255, 0.5);
      filter: drop-shadow(0px 4px 15px rgba(255, 255, 255, 0.25));
      border-radius: 7.5px;
      background: transparent;
      font-weight: 700;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.05em;
      padding: 10px 0px;
      text-transform: uppercase;
      color: #FFFFFF;
      cursor: pointer;
      transition: border 0.3s;
    }

    .wemake__button:hover {
      border: 2px solid #fff;
    }
  }
}

.howtoorder {
  margin-top: 45px;
  margin-bottom: 45px;
  position: relative;

  .howtoorder__bg {
    background: url(./assets/images/phone.webp);
    background-position: right bottom -32px;
    background-repeat: no-repeat;
    background-size: 429px 531px;
    position: absolute;
    right: -120px;
    bottom: -210px;
    width: 100%;
    height: 127%;
  }
}

.howtoorder__title {
  color: #252525;
  margin: 0px;
  max-width: 330px;
  line-height: 80%;
  padding-bottom: 30px;
}


.howtoorder__list {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 30px;
  overflow: hidden;

  li {
    transition: opacity .3s;
    background: #0040E6;
    width: calc(100%/4);
    position: relative;
    z-index: 1;
  }

  li a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
  }

  li a:hover {
    opacity: .7;
  }

  li:nth-child(2n) {
    background: rgba(0, 64, 230, 0.8);
    backdrop-filter: blur(10px);
  }

  span {
    font-family: 'Dela Gothic One', sans-serif;
    font-size: 64px;
    line-height: 93px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 0px;
    margin: 0px;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    padding: 0px;
    margin: 0px;
  }

  p {
    padding: 0px;
    margin: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.66);
  }

  .howtoorder__list-item-wrapper {
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}


.request {
  margin-top: 45px;
  margin-bottom: 45px;

  .success {
    background: #8BD000;
    transition: all .3s;
  }

  .disabled {
    pointer-events: none;
    filter: grayscale(1);
    opacity: .3;
  }

  .error {
    background: #e91e63;
  }
}

.request__title {
  max-width: 417px;
  line-height: 100%;
  color: #fff;
  margin: 0px;
  padding: 0px;
}

.request__subtitle {
  max-width: 316px;
  font-size: 18px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.66);
  margin: 0px;
  padding: 25px 0px 0px 0px;

  strong {
    color: #fff;
  }
}

.request__wrapper {
  padding: 50px;
  background: #0040E6;
  border-radius: 30px;
  transition: background .3s;
  position: relative;
}

.request__cross-button {
  align-items: center;
  padding: 0px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  justify-content: flex-end;
  outline: none;
  z-index: 20;
  transition: transform 0.5s ease-in-out;
}

.request__cross-button:hover {
  transform: rotate(360deg);
}

.request__cross {
  position: absolute;
  right: 50px;
  top: 50px;
  cursor: pointer;
}

.request__wrapper-border {
  border: 1px solid white;
}

.request__input {
  font-family: "Roboto Condensed", sans-serif;
  width: 100%;
  height: 75px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}

.request__input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.request__input:nth-child(2) {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding-left: 30px;
}

.request__form {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: 30px;
  padding: 10px 10px 10px 30px;

  .request__submit-button {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 24px;
    line-height: 100%;
    color: #252525;
    border-radius: 23px;
    background: #fff;
    border: none;
    padding: 0px 25px;
    cursor: pointer;
    transition: all .3s;
  }

  .request__submit-button:focus {
    opacity: 1;
  }

  .request__submit-button:hover,
  .request__submit-button:active {
    opacity: .7;
  }
}


.map {
  width: 100vw;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  min-height: 431px;
  padding-bottom: 45px;
  background: url(./assets/images/map.webp);
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #0040E6;
    box-shadow: 0px 4px 9px rgba(0, 64, 230, 0.25);
    border-radius: 15px;
    padding: 19px 40px;
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    transition: all .3s;
  }

  a:hover,
  a:focus,
  a:active {
    opacity: .7;
    transform: translateY(-4px);
  }
}




.footer {
  background-color: #0040E6;

  .footer__wrapper {
    padding: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .footer__block {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .footer__block-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.07em;
    color: #FFFFFF;
  }

  .footer__block-logo-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin: 0px;
    padding: 0px;
  }

  .footer__logo {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #FFFFFF;
    padding: 0px;
    margin: 0px;
  }

  .footer__logo-sub-list {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 29px;

    li {
      font-family: 'Dela Gothic One';
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 12px;
      color: rgba(255, 255, 255, 0.2);
    }
  }

  .footer__logo-adress {
    display: flex;
    flex-direction: column;
    gap: 10px;

    div {
      display: flex;
      flex-direction: column;
    }

    h3 {
      color: rgba(255, 255, 255, 0.5);
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin: 0px;
      padding: 0px;
    }

    a {
      margin: 0px;
      padding: 0px;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #FFFFFF;
      text-decoration: underline;
      transition: all .3s;
      cursor: pointer;
    }

    a:hover {
      opacity: .7;
    }
  }
}

.footer__block-working-hours {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.5);
    margin: 0px;
    padding: 0px;
  }
}


.footer__block-contacts {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0px;
  padding: 0px;
  gap: 5px;

  li {
    display: flex;
    width: max-content;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(181, 181, 181, 0.28);
    border-radius: 38px;
    transition: background .3s;

    a {
      display: flex;
      gap: 5px;
      padding: 7px 18px;
      font-size: 12px;
      line-height: 14px;
      color: #FFFFFF;
      font-weight: bold;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  li:hover {
    background: #4775ED;
  }
}

.footer__block-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0px;
  padding: 0px;
  list-style: none;

  li {
    a {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: rgba(255, 255, 255, 0.5);
      transition: all .3s;
    }

    a:hover {
      color: #fff;
    }
  }
}


.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #000;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}


.catalog {
  margin: 0px 100px 25px 100px;
  background: #FFFFFF;
  border: 1px solid #D1D1D1;
  box-shadow: 0px 51px 105px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 25px;
  gap: 25px;
}

.catalog:last-child {
  margin-bottom: 100px;
}


@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


.catalog__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.catalog__header-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 15px 25px;
  border: 1px solid #0140E4;
  border-radius: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #0140E4;
  background: none;
  outline: none;
  cursor: pointer;
  transition: box-shadow .3s;
}

.catalog__header-button:hover {
  box-shadow: 0 0 0 8px #0040e51a;
}

.catalog__header-title {
  max-width: 80%;
}

.catalog__title {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #252525;
  padding: 0px;
  margin: 0px;
}

.catalog__subtitle {
  margin: 10px 0px 0px 0px;
  padding: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  max-width: 500px;
  display: flex;
  align-items: center;
  color: rgba(37, 37, 37, 0.66);
}

.catalog__list {
  display: flex;
  flex-direction: column;
  gap: 12.5px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height .3s ease;
}

.catalog__list_open {
  max-height: 10000px;
  overflow: visible;
}

.catalog__list-item:first-child {
  margin-top: 25px;
}

.catalog__list-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 25px;
  border: 1px solid #EFEFEF;
  border-radius: 15px;
  position: relative;
}

.catalog__list-item-img {
  background: #EBEBEB;
  border-radius: 7.5px;
  min-height: 240px;
  min-width: 240px;
  max-width: 240px;
  max-height: 240px;
  object-fit: contain;
}

.catalog__list-item-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 0px;
  width: 100%;
}

.catalog__list-item-category {
  margin: 0px;
  padding: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  color: rgba(37, 37, 37, 0.6);
}

.catalog__list-item-name {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  display: flex;
  color: #252525;
  padding: 0px;
  margin: 0px;
  margin-bottom: 15px;
}

.catalog__list-item-specs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.catalog__list-item-specs-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 17px;
  gap: 10px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 26px;
  color: #252525;

  span {
    opacity: .66;
  }
}

.catalog__order-button {
  color: #fff;
  position: absolute;
  right: 30px;
  bottom: 30px;
  background: #0040E6;
  box-shadow: 0px 11px 33px rgba(0, 64, 230, 0.25);
  border-radius: 6px;
  padding: 10px 50px;
  border: none;
  gap: 5px;
  cursor: pointer;
}

.site__modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #0040e6;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  transform: translateY(-20px);

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
  }
}

.contacts__list {
  list-style: none;
  padding: 0px;
  margin: 0px;
  padding-top: 25px;
  gap: 35px;
  display: flex;
  flex-direction: column;
}

.contacts__list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px 50px;
  background: linear-gradient(180deg, #1758FF 0%, rgba(23, 88, 255, 0.64) 100%);
  border: 1px solid rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(21.5px);
  border-radius: 86px;
}

.contacts__list-icon {
  background: rgba(0, 0, 0, 0.17);
  border-radius: 84px;
  padding: 15px;
}

.contacts__list-item-body-wrapper {
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
}

.contcats__list-item-body {
  padding: 0px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin: 0px;
  color: #fff;
}

.contacts__list-item-title {
  margin: 0px;
  padding: 0px;
  color: rgba(255, 255, 255, 0.66);
  font-weight: 400;
  font-size: 18px;
}

.contacts__list-item-value {
  margin: 0px;
  padding: 0px;
  font-weight: 700;
  color: #fff;
}

.contacts__list-item-button {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 45px;
  border: 1px solid #ffffff82;
  border-radius: 180px;
  background: none;
  color: #fff;
  font-size: 18px;
  transition: all .3s;
  text-align: center;
}

.contacts__list-item-button:hover,
.contacts__list-item-button:active,
.contacts__list-item-button:focus {
  border: 1px solid #fff;
}

.visible-mobile {
  display: none;
}

.header__mobile-nav-wrapper-cross {
  background: rgba(0, 65, 231, 0.7882352941);
  display: flex;
  border: 2px solid rgba(255, 255, 255, 0.1607843137);
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-top: 25px;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.mobile__menu-invisible {
  opacity: 0;
  visibility: hidden;
  left: -350px;
}

.mobile__menu-visible {
  left: 0px;
  display: flex;
  position: absolute;
  width: 80%;
  height: 100vh;
  left: 0px;
  top: 0px;
  flex-direction: column;
  z-index: 20;
  background: rgb(255 255 255);
  gap: 20px;
  border-radius: 0px 0px 20px 0px;

  .header__nav-item {
    color: #222;
    opacity: 1;
    padding: 20px;
    text-align: right;
    border-top: 1px solid #e6e6e6;
  }

  .header__nav-item.active {
    color: #0040E6;
  }
}

.header__mobile-nav {
  position: relative;
}

.header__mobile-nav-wrapper {
  transition: left .3s ease-in;
  display: flex;
  position: absolute;
  height: 100vh;
  top: 0px;
  flex-direction: column;
  z-index: 20;
  background: rgb(255, 255, 255);
  gap: 0px;
  border-radius: 0px 0px 20px 0px;
}

.header__mobile-nav-button {
  border-radius: 6px;
  border: none;
  padding: 0px;
  margin: 0px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  background: #1346d0;
  padding: 10px;
  border: 1px solid white;

  img {
    width: 100%;
    height: 100%;
  }
}


@media screen and (max-width: 1440px) {
  .content {
    padding: 0px 25px;
  }

  .header__content-wrapper {
    padding: 25px 0px;
  }

  .hero__wrapper {
    padding: 0px;
  }

  .slider__arrow-left {
    left: -25px;
  }

  .slider__arrow-right {
    right: -25px;
  }

  .page__wrapper {
    padding: 20px 0px;
  }

  .catalog {
    margin: 0px 0px 15px 0px;
  }
}

@media screen and (max-width: 1313px) {
  .delivery__bg {
    background-position: center right;
    right: 20px;
    top: 0px;
    width: 400px;

    .delivery__bg-box {
      background-size: contain;
    }
  }
}


@media screen and (max-width: 1235px) {
  .delivery__bg {
    width: 300px;
    background-position: top right;

    .delivery__bg-box {
      background-size: contain;
      background-position: top right;
    }
  }

  .delivery {
    min-height: 500px;
  }

  .footer .footer__wrapper {
    padding: 50px 0px;
  }
}

@media screen and (max-width: 1200px) {
  .about__photo {
    width: 314px;
    height: 314px;
    background-size: cover;
    border: 41px solid #fff;
    right: 25px;
    top: 100px;
  }
}

@media screen and (max-width: 1140px) {
  .header__content-wrapper {
    align-items: flex-start;
  }

  .header__left-side {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .hero__wrapper {
    min-height: auto;
  }

  .header__nav {
    gap: 15px;
  }

  .bg__image {
    position: absolute;
    right: -105px;
    bottom: -373px;
    width: 1200px;
    height: 1200px;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: right bottom;
    z-index: -1;
  }

  .header__nav {
    margin-top: 8px;
  }

  .delivery__bg {
    width: 300px;
    background: none;
    filter: grayscale(1) opacity(0.5);

    .delivery__bg-box {
      background-size: contain;
      background-position: top right;
    }
  }

  .delivery {
    min-height: 399px;
  }

  .delivery__slider {
    max-width: 100%;
  }

  .whyus__features-image-human {
    left: -122px;
  }

  .whyus__features-list {
    padding: 50px 0px;
  }

  .wemake__showcase li {
    width: calc(50% - 50px);
    padding: 25px;
  }

  .howtoorder__list .howtoorder__list-item-wrapper {
    padding: 25px;
  }

  .request__wrapper {
    padding: 25px;
  }

  .request__cross {
    right: 25px;
    top: 25px;
  }

  .footer .footer__block-title {
    font-size: 18px;
    line-height: 100%;
  }

  .footer__block-menu li a {
    font-size: 16px;
  }

  .footer__block-working-hours p {
    font-size: 16px;
  }

  .footer .footer__logo {
    font-size: 14px;
    line-height: 100%;
  }

  .footer .footer__block-logo-wrapper {
    gap: 5px;
  }

  .footer .footer__logo-sub-list {
    justify-content: flex-start;
    gap: 5px;
    flex-direction: column;
  }

  .footer .footer__logo-adress a {
    font-size: 16px;
    max-width: 213px;
  }

  .footer .footer__logo-adress h3 {
    font-size: 14px;
  }
}

@media screen and (max-width:991px) {
  .whyus {
    margin-top: 75px;
  }

  .whyus__features-image {
    display: none;
  }

  .whyus__features {
    justify-content: center;
    align-items: center;
  }

  .whyus__features-list-ul {
    max-width: 100%;
  }

  .whyus__avito img {
    width: 110px;
  }

  .whyus__avito-button {
    min-width: 134px;
    min-height: 43px;
    font-size: 16px;
  }

  .whyus__avito-title {
    font-size: 16px;
  }

  .howtoorder__list {
    flex-wrap: wrap;
  }

  .howtoorder__list li {
    width: 100%;
  }

  .howtoorder__list li:nth-child(2n) {
    background: #0040E6;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }

  .request__form {
    flex-wrap: wrap;
    padding: 25px;
  }

  .request__input:nth-child(2) {
    padding-left: 0px;
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }

  .request__form .request__submit-button {
    width: 100%;
    padding: 25px 25px;
  }

  .catalog__list-item-img {
    min-width: 150px;
    min-height: 150px;
    width: 150px;
    height: 150px;
  }

  .catalog__order-button {
    position: initial;
    margin-top: 15px;
    align-self: flex-end;
  }

  .about__photo {
    position: initial;
    padding: 0px;
    border: 0px;
    box-shadow: 0 0 0px 20px #0000000d;
    margin-top: 45px;
  }
}

@media screen and (max-width:768px) {
  .footer .footer__wrapper {
    flex-wrap: wrap;
    padding: 25px 0px;
    gap: 25px;
  }

  .whyus__avito-blur {
    display: none;
  }

  .footer-logo {
    order: 5;
  }

  .footer-hours {
    order: 1
  }

  .footer-contacts {
    order: 2;
  }

  .footer-navigation {
    order: 0;
  }
}

@media screen and (max-width: 752px) {
  body {
    background-size: 1120px;
  }

  .bg__image {
    bottom: -279px;
    background-size: 70%;
  }

  .invisible-mobile {
    display: none;
  }

  .visible-mobile {
    display: flex;
  }

  .header__nav {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border: 1px solid white;
    border-radius: 15px;
    margin-top: 0px;
  }

  .hero__title {
    font-size: 36px;
  }
}

@media screen and (max-width:670px) {
  .bg__image {
    bottom: -279px;
    background-size: 60%;
    right: -66px;
  }
}

@media screen and (max-width:606px) {

  h1,
  h2 {
    font-size: 48px;
    line-height: 100%;
  }

  .delivery__title {
    max-width: 340px;
  }

  .wemake__showcase li {
    width: 100%;
  }

  .wemake {
    margin-top: 45px;
  }

  .catalog__list-item {
    flex-direction: column;
  }

  .catalog__list-item-img {
    width: 100%;
    height: auto;
  }

  .catalog__header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
  }

  .catalog__header-button {
    width: 100%;
  }
}

@media screen and (max-width: 592px) {
  .catalog__list-item-img {
    width: 100%;
    min-width: 100%;
  }

  .whyus__features-list-item {
    padding: 10px 0px;
  }

  .content {
    padding: 0px 15px;
  }

  .request__form {
    padding: 0px;
    border: none;
  }

  .hero__button {
    display: flex;
    max-width: 100%;
  }

  .bg__image {
    width: 600px;
    background-size: 600px;
  }

  .whyus__features-list-ul {
    flex-direction: column;
    gap: 30px
  }

  .whyus__features-list-item {
    width: auto;
  }

  .howtoorder__list li {
    width: 100%;
  }

  .howtoorder__list span {
    font-size: 48px;
    line-height: 100%;
  }

  .contacts__list-item {
    padding: 25px;
    flex-direction: column;
    border-radius: 15px;
    gap: 25px;
  }

  .contacts__list-item-body-wrapper {
    width: 100%;
  }

  .contacts__list-item-button {
    width: 100%;
    margin: 0px;
    padding: 10px 0px;
  }

  .footer__block-contacts {
    li {
      a {
        padding: 14px 18px;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width:470px) {
  .request__form .request__submit-button {
    width: 100%;
    padding: 15px 0px;
    font-size: 17px;
  }

  h1,
  h2 {
    font-size: 32px;
  }

  .delivery {
    margin-top: 224px;
    min-height: auto;
  }

  .delivery__title {
    max-width: 217px;
  }

  .gallery__title {
    max-width: 250px;
  }

  .bg__image {
    right: 0px;
    bottom: -200px;
    max-width: 100%;
    background-size: 420px;
  }

  .hero__socials .hero__socials-list {
    flex-wrap: wrap;
  }

  .slider__wrapper {
    width: 100vw !important;
    max-width: 100vw !important;
    left: -15px;
  }

  .delivery__slider,
  .gallery__slider {
    padding-left: 15px !important;
    padding-right: 60px !important;
  }

  .slider__arrow {
    display: none;
  }

  .whyus__avito {
    flex-direction: column;
    gap: 20px;
  }

  .request__title {
    max-width: 203px;
  }

  .request__input {
    font-size: 18px;
  }

  .footer .footer__wrapper {
    flex-direction: column;
    gap: 50px;
  }

  .footer__block-menu {
    gap: 5px;
  }

  .catalog__list {
    gap: 10px;
  }

  .catalog__list-item-img {
    border-radius: 0px;
  }

  .catalog__list-item {
    padding: 0px;
    gap: 0px;
    overflow: hidden;
  }

  .catalog__list-item-specs {
    gap: 5px;
    ;
  }

  .catalog__list-item-specs-item {
    font-size: 14px;
    gap: 4px;
  }

  .catalog__list-item {
    border: none;
    overflow: visible;
    padding: 0px;
  }

  .catalog__list-item-body {
    margin: 20px 0px;
    width: auto;
  }

  .hero__title {
    font-size: 28px;
  }

  .catalog__order-button {
    position: initial;
    margin-top: 30px;
    align-self: flex-end;
    width: 100%;
  }

  .catalog__list-item-name {
    font-size: 24px;
    line-height: 125%;
  }
}

@media screen and (max-width: 375px) {
  .bg__image {
    background-size: 340px;
    bottom: -179px;
  }

  .map {
    a {
      width: 200px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      font-size: 18px;
    }
  }

  .contacts__list-item-body-wrapper {
    gap: 10px;
  }
}